<template>
	<div>
		<ChartFilters
			@findTransactions="search"
			style="margin-bottom: 0px"
		/>
		<Base class="container" style="margin-top: 20px">
			<div>
				<ChartMedia
					:title="`Despesas por categorias`"
					type="bar"
					:showXAxisLabel="true"
					:showZoom="true"
					:chartKey="3"
					:showLegend="true"
					:filters="filters"
				/>
			</div>
		</Base>
	</div>
</template>

<script>
	import Base from "@/templates/Base";
	import ChartMedia from "@/components/charts/ChartMedia.vue";
	import ChartFilters from "@/components/charts/ChartFilters.vue";

	export default {
		components: {
			ChartMedia,
			Base,
			ChartFilters
		},

		data () {
			return {
				filters: {}
			};
		},

		computed: {

		},

		watch: {
		},

		async mounted () {
		},

		methods: {
			search (filters) {
				this.filters = filters;
			}
		}
	};
</script>

<style scoped>
	.container {
		max-width: 90.5%;
	}
</style>
